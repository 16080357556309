import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import ClientPopup from '../containers/ClientPopup'

import { tokenUrl, fromSeconds, toSeconds } from '../actions/helpers'

import { updateClient, getRoles, getTokens } from '../actions/api'
import { clearSelectedClient, setClientName, setClientFullName, setClientStatus, setClientRole, setCreateClientMode,
		setClientActiveTokenNumber, setClientTokenExpiredAfterSecond, setClientExtendedData, setShowClientTokensView,
		setClientCreatedDate, setClientPassword, setClientEmail, setShowCreateOrEditForm, setSelectedItem, setTokensList } from '../actions/localStates'

class CreateEditClientView extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	statusValues: ["ACTIVE", "BLOCKED", "REVOKED"],
	    	selectedStatus: "",
	    	selectedRoles: this.props.selectedClient.clientRoles || [],
	    	availabelRoles: [],
	    	showPopup: false,
			clientNameIsEmpty: false,
			passwordIsEmpty: false,
			confirmedPasswordNotEqual: false,
			confirmPassword: "",
			passwordMinLength: true,
			errorMessageForClient: "",
			errorMessageForPassword: "",
			errorMessageForConfirmPassword: "",
			activeTokenPeriod: ["seconds", "minutes", "hours", "days", "months", "years"],
			current_period: "seconds",
			displayed_value: 0,
			clientId: this.props.selectedClient.clientName || ""
	    }

	    this.keyUpHandler = this.keyUpHandler.bind(this, 'returnToUsers');
	    this.keyUpEnterHandler = this.keyUpEnterHandler.bind(this, 'saveChanges');

	    this.onStatusChanged = this.onStatusChanged.bind(this);
	    this.onRoleChanged = this.onRoleChanged.bind(this);

	    this.onClientNameChanged = this.onClientNameChanged.bind(this);
	    this.onClientFullNameChanged = this.onClientFullNameChanged.bind(this);

	    this.onActiveTokenNumberChanged = this.onActiveTokenNumberChanged.bind(this);
		this.onTokenExpiredAfterSecChanged = this.onTokenExpiredAfterSecChanged.bind(this);

		this.onExtendedDataChanged = this.onExtendedDataChanged.bind(this);
		this.onCreatedDateChanged = this.onCreatedDateChanged.bind(this);
		this.onClientPasswordChanged = this.onClientPasswordChanged.bind(this);

		this.handleCheckRole = this.handleCheckRole.bind(this);
		this.handleChangePassword = this.handleChangePassword.bind(this);
		this.onConfirmedPasswordChanged = this.onConfirmedPasswordChanged.bind(this);

		this.onClientEmailChanged = this.onClientEmailChanged.bind(this);
		this.handleShowTokens = this.handleShowTokens.bind(this);

		this.validateFields = this.validateFields.bind(this);

	    this.cancelUpdate = this.cancelUpdate.bind(this);

	    this.onActivePeriodChanged = this.onActivePeriodChanged.bind(this);
	    this.onDisplayedValueChanged = this.onDisplayedValueChanged.bind(this);
	    this.setCurrentActivePeriod = this.setCurrentActivePeriod.bind(this);

	    this.nameChanged = this.nameChanged.bind(this);
	}

	componentDidMount() {
		document.addEventListener('keyup', this.keyUpHandler);
		document.addEventListener('keyup', this.keyUpEnterHandler);
		var curr_date;

		this.props.actions.getRoles(this.props.base.serviceUrl, this.props.login.accessToken)
		.then((response) => {
			var roles = [];
			response.forEach((role, index) => {
				if (role.id !== "AUTHORIZED_USER" && role.id !== "UOS_AUTHORIZED_CLIENT") {
					roles.push(role)
				}
			})
			
			this.setState({availabelRoles: roles})
		});

		if (this.props.base.createClientMode) {
			this.props.actions.setClientStatus("ACTIVE")
		}

		if (this.props.base.createClientMode) {
			this.setState({"displayed_value": 0})
			this.setState({"current_period": "seconds"})
		} else {
			if (this.props.selectedClient.clientTokenExpiredAfterSec > 0) {
				curr_date = this.props.actions.fromSeconds(this.props.selectedClient.clientTokenExpiredAfterSec)
				this.setState({"displayed_value": curr_date.value})
				this.setState({"current_period": curr_date.unit})
			}
			
		}
	}

	componentWillUnmount () {
		document.removeEventListener('keyup', this.keyUpHandler);
		document.removeEventListener('keyup', this.keyUpEnterHandler);
	}

	onActivePeriodChanged(e) {
		this.setState({"current_period": e.target.value})
		this.setCurrentActivePeriod(this.props.actions.toSeconds(this.state.displayed_value, e.target.value))
	}

	onDisplayedValueChanged(e) {
		this.setState({"displayed_value": e.target.value})
		this.props.actions.toSeconds(e.target.value, this.state.current_period)
		this.setCurrentActivePeriod(this.props.actions.toSeconds(e.target.value, this.state.current_period))
	}

	setCurrentActivePeriod (value) {
		this.props.actions.setClientTokenExpiredAfterSecond(value)
	}

	hoverPass() {
        this.setState({showPassword: true})
	}

	outPass() {
	    this.setState({showPassword: false})
	}

	hoverConfirmPass() {
	    this.setState({showConfirmPassword: true})
	}

	outConfirmPass() {
	    this.setState({showConfirmPassword: false})
	}

	validateFields () {
		if (this.props.base.createClientMode)	{
			this.props.selectedClient.clientName.length > 0
				? this.setState({clientNameIsEmpty: false})
				: this.setState({clientNameIsEmpty: true}, () => {
					this.setState({errorMessageForClient: "needToBeFilledField"})
					})

			this.props.selectedClient.clientPassword.length > 0
				? this.setState({passwordIsEmpty: false})
				: this.setState({passwordIsEmpty: true}, () => {
					this.setState({errorMessageForPassword: "needToBeFilledField"})
					})

			if (!this.state.clientNameIsEmpty && !this.state.passwordIsEmpty) {
				if ((this.props.selectedClient.clientPassword.length >= 8) && (this.props.selectedClient.clientPassword === this.state.confirmPassword)) {
					this.setState({confirmedPasswordNotEqual: false})
					this.props.createUpdateClient()
				} else {
					this.setState({confirmedPasswordNotEqual: true}, () => {
						this.setState({errorMessageForConfirmPassword: "needToBeFilledField"})
					})
				}
			}
		} else {
			this.props.createUpdateClient()
		}
	}

	createUpdateClient() {
		this.validateFields();
	}

	cancelUpdate () {
		this.props.actions.clearSelectedClient();
		this.props.actions.setShowCreateOrEditForm(false);
		this.props.actions.setCreateClientMode(false);
		this.props.actions.setSelectedItem(null);
	}

	onClientEmailChanged (e) {
		this.props.actions.setClientEmail(e.target.value);
	}

	onStatusChanged (e) {
		this.props.actions.setClientStatus(e.target.value);
	}

	onRoleChanged (value) {
		this.setState({"selectedRole": value})
	}

	onClientPasswordChanged (e) {
		this.props.actions.setClientPassword(e.target.value)
		e.target.value.length > 0 && e.target.value.length < 8
			? this.setState({passwordIsEmpty: false}, () => {
				this.setState({errorMessageForPassword: "passMinLength"})
				})
			: this.setState({passwordIsEmpty: false}, () => {
				this.setState({errorMessageForPassword: ""})
				})

		if (this.state.confirmPassword.length > 0 && (this.state.confirmPassword === e.target.value)) {
			this.setState({confirmedPasswordNotEqual: false})
			this.setState({errorMessageForConfirmPassword: ""})
		}
	}

	onConfirmedPasswordChanged (e) {
		this.setState({"confirmPassword": e.target.value})
		this.props.selectedClient.clientPassword === e.target.value
		? this.setState({confirmedPasswordNotEqual: false}, () => {
			this.setState({errorMessageForConfirmPassword: ""})
			})
		: this.setState({confirmedPasswordNotEqual: true}, () => {
			this.setState({errorMessageForConfirmPassword: "passNotEqual"})
			})
	}

	nameChanged () {
		this.state.clientId.length > 0
			? this.setState({clientNameIsEmpty: false}, () => {
				this.setState({errorMessageForClient: ""})
				})
			: this.setState({clientNameIsEmpty: true}, () => {
				this.setState({errorMessageForClient: "needToBeFilledField"})
				})
	}

	onClientNameChanged (e) {
		var replacedString = e.target.value.replace(/\s+/g, '');
		this.setState({"clientId": replacedString}, () => {
			this.nameChanged()
		})
		this.props.actions.setClientName(replacedString)
	}

	onClientFullNameChanged (e) {
		this.props.actions.setClientFullName(e.target.value)
	}

	onActiveTokenNumberChanged (e) {
		this.props.actions.setClientActiveTokenNumber(e.target.value)
	}

	onTokenExpiredAfterSecChanged (e) {
		this.props.actions.setClientTokenExpiredAfterSecond(e.target.value)
	}

	onExtendedDataChanged (e) {
		this.props.actions.setClientExtendedData(e.target.value)
	}

	onCreatedDateChanged (e) {
		this.props.actions.setClientCreatedDate(e.target.value)
	}

	handleChangePassword () {
		this.setState({
	        showPopup: !this.state.showPopup
	    });
	}

	handleCheckRole (value, e) {
		var tempArr = this.state.selectedRoles;
		function removeItem(array, item) {
		    for (var i in array) {
		        if (array[i] === item) {
		            array.splice(i, 1);
		            break;
		        }
		    }
		}
		if (e.target.checked) {
			tempArr.push(value.id);
		} else {
			removeItem(tempArr, value.id);
		}

		this.setState({selectedRoles: tempArr});
		this.props.actions.setClientRole(tempArr);
	}

	handleShowTokens () {
        var params = {
            "url": this.props.base.serviceUrl,
            "type": "token",
            "page": 0,
            "size": 10,
            "subjectType": "CLIENT",
            "subject": this.props.selectedClient.clientName
        }
        this.props.actions.getTokens(this.props.actions.tokenUrl(params), this.props.login.accessToken)
        .then((response) => {
            this.props.actions.setTokensList(response.content);
            console.log(response);
        })

        this.props.actions.setShowClientTokensView(true);
    }

	renderFields () {
		let values = [];
		let client = this.props.selectedClient.selectedClient;

		Object.getOwnPropertyNames(client).forEach(function(val, idx, array) {
			values.push(
				<div key={idx} className="input-group">
				    <label className="col-md-4 col-lg-4 col-sm-4 col-xs-4">{val}</label>
				    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
				        {client[val]}
				    </div>
				</div>
			)
		});
	    return values;
	}

	keyUpHandler(refName, e) {
		if (e.keyCode === 8 && e.target.tagName === 'BODY') {
			this.props.actions.clearSelectedClient();
    		this.props.actions.setShowCreateOrEditForm(false)
    		this.props.actions.setSelectedItem(null)
			document.activeElement.blur();
		}
	}

	keyUpEnterHandler(refName, e) {
	    if (e.keyCode === 13 && this.state.showPopup === false) {
	      this.createUpdateClient()
	    }
	}

	render() {
		let statusColor, activeClass, activeClassDiv;
		activeClass  = 'edit-view'
		if (this.props.base.drawerOpen) {
		    activeClass = 'edit-view open'
		};

		activeClassDiv  = 'topright-div'
		if (this.props.base.drawerOpen) {
		    activeClassDiv = 'topright-div open'
		}

		switch(this.props.selectedClient.clientStatus) {
			case "ACTIVE":
				statusColor = "col-12 status-placeholder bg-green"
				break;
			case "BLOCKED":
				statusColor = "col-12 status-placeholder bg-grey"
				break;
			case "REVOKED":
				statusColor = "col-12 status-placeholder bg-red"
				break;

			default:
				statusColor = "col-12 status-placeholder bg-green"
				break;
		}
		
		return (
			<div>
				{
					this.props.base.createClientMode
					? null
					: <div className={activeClassDiv}>
						<button className="btn btn-outline-info" style={{marginRight: "5px"}} disabled={this.props.selectedClient.clientStatus === "REVOKED"} onClick={this.handleChangePassword}>{i18next.t("changePasswordLabel")}</button>
						<button className="btn btn-outline-info" style={{marginRight: "5px"}} disabled={this.props.selectedClient.clientStatus === "REVOKED"} onClick={this.handleShowTokens}>{i18next.t("clientsTokensLabel")}</button>
					</div>
				}
				<div className={activeClass}>

				<div className="col-8">
					<div className="row col-12">
						<div className="col-6" style={{paddingLeft: "0px"}}>
			                <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("clientUniqueIdLabel")}</label>
			                <div className="col-12">
								<input className={this.state.clientNameIsEmpty ? "form-control is-invalid" : "form-control"} type="text" disabled={!this.props.base.createClientMode || this.props.selectedClient.clientStatus === "REVOKED"} value={this.state.clientId} onChange={this.onClientNameChanged} />
							</div>
							{
								this.state.errorMessageForClient.length > 0
								? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessageForClient)}</span>
								: null
							}
						</div>
						<div className="col-6" style={{paddingLeft: "0px"}}>
							<label className="col-6 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("clientStatusLabel")}</label>
			                <div className="col-12">
			                	<div className={statusColor}>
									<span>{i18next.t(this.props.selectedClient.clientStatus)}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="row col-12">
						<div className="col-6" style={{paddingLeft: "0px"}}>
							<label className="col-6 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", marginTop: "5px"}}>{i18next.t("clientNameLabel")}</label>
			                <div className="col-12">
								<input className="form-control" type="text" disabled={this.props.selectedClient.clientStatus === "REVOKED"} value={this.props.selectedClient.clientFullName} onChange={this.onClientFullNameChanged} />
							</div>
							<label className="col-6 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", marginTop: "5px"}}>{i18next.t("emailLabel")}</label>
			                <div className="col-12">
								<input className="form-control" type="text" disabled={this.props.selectedClient.clientStatus === "REVOKED"} value={this.props.selectedClient.clientEmail} onChange={this.onClientEmailChanged} />
							</div>
						</div>
						<div className="col-6" style={{paddingRight: "30px"}}>
							<label className="col-6 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingLeft: "0px", paddingBottom: "0px", marginTop: "5px"}}>{i18next.t("rolesLabel")}</label>
			                <div className="col-12" style={{border: "1px solid rgba(0, 0, 0, 0.125)", borderRadius: "0.25rem", "listStyle": "none", "minHeight": "110px"}}>
			                	<div className="row col-12" style={{paddingLeft: "0px", paddingTop: "5px"}}>
			                		{
			                			this.state.availabelRoles.length > 0
			                			? this.state.availabelRoles.map((role, index) => {
			                				return (<div key={index} className="form-check form-check-inline col-12" style={{marginBottom: "5px"}}>
												<input type="checkbox" disabled={this.props.selectedClient.clientStatus === "REVOKED"} checked={this.props.selectedClient.clientRoles !== undefined
														? (this.props.selectedClient.clientRoles.includes(this.state.availabelRoles[index].id) ? true : false )
														: false} className="form-check-input" onChange={this.handleCheckRole.bind(this, role)} />
												<label className="form-check-label">
													{	
														this.state.availabelRoles[index].description
													}
												</label>
											</div>)
			                			})
			                			: null
			                		}
								</div>
							</div>
						</div>
					</div>

					{
						this.props.base.createClientMode
						? <div className="row col-12">
							<div className="col-6" style={{paddingLeft: "0px"}}>
								<label className="col-6 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", marginTop: "5px"}}>{i18next.t("passwordTitle")}&nbsp;
	                              <i className="fa fa-info-circle"></i>
	                              <div className="tooltip">{i18next.t("requirementsPassword")}</div>
	                            </label>
				                <div className="col-12 input-group">
									<input className={(this.state.errorMessageForPassword.length > 0) ? "form-control is-invalid" : "form-control"} autocomplete="new-password" type={this.state.showPassword ? "input" : "password"} value={this.props.selectedClient.clientPassword} onChange={this.onClientPasswordChanged} />
									<div className="input-group-append">
	                                    <div className="input-group-text" onMouseOver={this.hoverPass.bind(this)} onMouseOut={this.outPass.bind(this)}><i className="fa fa-eye"></i></div>
	                                </div>
								</div>
								{
									this.state.errorMessageForPassword.length > 0
									? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessageForPassword)}</span>
									: null
								}							
							</div>
							<div className="col-6" style={{paddingLeft: "0px"}}>
								<label className="col-6 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", marginTop: "5px"}}>{i18next.t("confirmPassLabel")}&nbsp;
	                              <i className="fa fa-info-circle"></i>
	                              <div className="tooltip">{i18next.t("requirementsPassword")}</div>
                                </label>
				                <div className="col-12 input-group">
									<input className={this.state.confirmedPasswordNotEqual ? "form-control is-invalid" : "form-control"} autocomplete="new-password" value={this.state.confirmPassword} onChange={this.onConfirmedPasswordChanged} type={this.state.showConfirmPassword ? "input" : "password"} />
									<div className="input-group-append">
	                                    <div className="input-group-text" onMouseOver={this.hoverConfirmPass.bind(this)} onMouseOut={this.outConfirmPass.bind(this)}><i className="fa fa-eye"></i></div>
	                                </div>
								</div>
								{
									this.state.errorMessageForConfirmPassword.length > 0
									? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessageForConfirmPassword)}</span>
									: null
								}
							</div>
						</div>
						: null
					}

					<div className="row col-12">
						<div className="col-4 row" style={{paddingLeft: "0px", paddingRight: "0px"}}>
							<div className="col-12 row" style={{paddingLeft: "0px", paddingRight: "0px"}}>
								<label className="col-8 col-form-label" style={{fontSize: "15px", color: "#b5b1af", marginTop: "30px"}}>{i18next.t("activeTokensClientNumberTitle")}</label>
				                <div className="col-4" style={{"paddingRight": "0px"}}>
									<input className="form-control col-12" style={{marginTop: "30px"}} disabled={this.props.selectedClient.clientStatus === "REVOKED"} type="text" value={this.props.selectedClient.clientActiveTokenNumber} onChange={this.onActiveTokenNumberChanged} />
								</div>
							</div>
							<div className="col-12 row" style={{paddingLeft: "0px", paddingRight: "0px"}}>
								<label className="col-5 col-form-label" style={{fontSize: "15px", color: "#b5b1af", marginTop: "10px", "paddingRight": "0px"}}>{i18next.t("tokensExpiredAfterTitle")}</label>
				                <div className="col-3" style={{paddingLeft: "0px", "paddingRight": "0px"}}>
									<input className="form-control col-12" style={{marginTop: "10px"}} disabled={this.props.selectedClient.clientStatus === "REVOKED"} type="text" value={this.state.displayed_value} onChange={this.onDisplayedValueChanged} />
								</div>
								<div className="col-4" style={{marginTop: "10px", "paddingRight": "0px"}}>
									<select style={{"paddingRight": "0px"}} disabled={this.props.selectedClient.clientStatus === "REVOKED"} defaultValue={this.state.current_period} className="form-control" onChange={this.onActivePeriodChanged}>
										{
		                                  this.state.activeTokenPeriod.map((value, index) =>
		                                    <option key={index} value={value} selected={this.state.current_period === value ? true : false}>{i18next.t(value)}</option>)
		                                }
									</select>
								</div>
							</div>
						</div>

						<div className="col-8">
							<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", marginTop: "5px"}}>{i18next.t("advancedInfo")}</label>
			                <div className="col-12">
								<textarea className="form-control" rows="3" value={this.props.selectedClient.clientExtendedData} disabled={this.props.selectedClient.clientStatus === "REVOKED"} onChange={this.onExtendedDataChanged} />
							</div>
						</div>
					</div>

					

					<div className="row" style={{float: "right", marginRight: "45px"}}>
						{
							this.props.base.createClientMode
							? <button className="btn btn-info" style={{marginTop: "10px"}} onClick={this.createUpdateClient.bind(this)}>{i18next.t("createLabel")}</button>
							: <button className="btn btn-info" style={{marginTop: "10px"}} disabled={this.props.selectedClient.clientStatus === "REVOKED"} onClick={this.createUpdateClient.bind(this)}>{i18next.t("applyChangesLabel")}</button>
						}
						&nbsp;
						<button className="btn btn-outline-info" style={{marginTop: "10px"}} onClick={this.cancelUpdate}>{i18next.t("cancelLabel")}</button>
					</div>
				</div>
				<div>
					{
						this.state.showPopup
						? <ClientPopup closeClientPopup={this.handleChangePassword.bind(this)} />
			            : null
			        }
		        </div>
			</div>
		</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        selectedClient: state.clientReducer,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    clearSelectedClient,
	    updateClient,
	    setClientName,
	    setClientFullName,
	    setClientStatus, setClientRole,
		setClientActiveTokenNumber,
		setClientTokenExpiredAfterSecond,
		setClientExtendedData,
		setClientCreatedDate, setClientPassword,
		setClientEmail,
		setShowCreateOrEditForm,
		getRoles, setSelectedItem,
		setShowClientTokensView,
		getTokens,
		tokenUrl,
		setTokensList,
		setCreateClientMode,
		fromSeconds, toSeconds
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditClientView);